import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/specialties',
    name: 'Specialties',
    component: () => import('../views/Specialties.vue'),
  },
  {
    path: '/my-experience',
    name: 'MyExperience',
    component: () => import('../views/MyExperience.vue'),
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('../views/Services.vue'),
  },
  {
    path: '/rates',
    name: 'Rates',
    component: () => import('../views/Rates.vue'),
  },
  {
    path: '/vlog',
    name: 'Vlog',
    component: () => import('../views/Vlog.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/Contact.vue'),
  },
  {
    path: '/ifs',
    name: 'ifs',
    component: () => import('../views/IFS.vue'),
  },
  {
    path: '/cbt',
    name: 'cbt',
    component: () => import('../views/CBT.vue'),
  },
  {
    path: '/emdr',
    name: 'emdr',
    component: () => import('../views/EMDR.vue'),
  },
  {
    path: '/supervision',
    name: 'supervision',
    component: () => import('../views/Supervision.vue'),
  },
  {
    path: '/dbt',
    name: 'dbt',
    component: () => import('../views/DBT.vue'),
  },
  {
    path: '/bsp',
    name: 'bsp',
    component: () => import('../views/BSP.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

export default router;
