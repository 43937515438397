<template>
  <v-row>
    <v-col :cols="cols" :md="md"></v-col>
    <v-col :cols="12 - (2 * cols)" :md="12 - (2 * md)">
      <v-img :src="`${publicPath}${path}`">
      </v-img>
    </v-col>
    <v-col :cols="cols" :md="md"></v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    path: {
      type: String,
      required: true,
    },
    md: {
      type: Number,
      default: 3,
    },
    cols: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    publicPath: process.env.BASE_URL,
  }),
};
</script>
