<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      color="blue-grey lighten-4"
      clipped
      app
    >
      <v-img
        :src="`${publicPath}img/cc-logo-cropped.png`"
        class="ma-6"
        eager
      >
      </v-img>
      <v-list>

        <v-list-item to="/" link>
          <v-list-item-action>
            <v-icon>mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group prepend-icon="mdi-information" no-action>

          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>About Me</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item to="/specialties" link>
            <v-list-item-action>
              <v-icon>mdi-brain</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Specialties</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/my-experience" link>
            <v-list-item-action>
              <v-icon>mdi-file-account</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>My Experience</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list-group>

        <v-list-item to="/services" link>
          <v-list-item-action>
            <v-icon>mdi-head-heart</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Services</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/rates" link>
          <v-list-item-action>
            <v-icon>mdi-receipt</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Rates and Insurance</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/contact" link>
          <v-list-item-action>
            <v-icon>mdi-email</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Contact</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>

      <template v-slot:append>
        <div>
          <v-card-title class="blue darken-4 12px">
            <v-btn
              v-for="social in socials"
              :key="social.link"
              class="pa-0 blue darken-4"
              :href="social.link"
              target="_blank"
              depressed
              dark
            >
              <v-icon >
                {{ social.icon }}
              </v-icon>
              @thecoeurcounselor
            </v-btn>
          </v-card-title>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar
      color="blue darken-4"
      dark
      :src="`${publicPath}img/wide_landscape_crop3.jpg`"
      prominent
      clipped-left
      app
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to bottom right, rgba(0,120,120,.4), rgba(13,71,161,0.7)"
        ></v-img>
      </template>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Coeur Counseling</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-row>
        <v-col cols="12">
          <v-alert
              color="red darken-1"
              dark
              class="ma-5"
              icon="mdi-alert"
              border="left"
              prominent>{{alert}}</v-alert>
        </v-col>
      </v-row>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    alert: 'My practice is now closed due to relocation. If you are a former client, record requests can be sent to the fax number on my contact page.',
    drawer: null,
    publicPath: process.env.BASE_URL,
    socials: [
      { icon: 'mdi-instagram', link: 'https://instagram.com/thecoeurcounselor' },
    ],
  }),
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
.v-application {
   font-family: 'Playfair Display', serif;
 }
</style>
