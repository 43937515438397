<template>
  <v-row>
    <v-col cols="1" md="3"></v-col>
    <v-col cols="10" md="6">
      <p class="text-left">{{text}}</p>
    </v-col>
    <v-col cols="1" md="3"></v-col>
  </v-row>
</template>

<script>
export default {
  props: ['text', 'header'],
};
</script>
