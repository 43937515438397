<template>
  <v-container>
    <PageTitle text="Coeur Counseling"/>
    <PageText :text="p1" />
    <v-row>
      <v-col cols="12">
        <h1 class="text-center">Sarah Bjorn, LCSW</h1>
      </v-col>
    </v-row>
    <PageImage path="img/sarah2.jpg" />
    <PageText :text="p2" />
    <PageText :text="p3" />
    <PageImage path="img/ss-steam-tea.jpg" />
  </v-container>
</template>

<script>
import PageText from '@/components/PageText.vue';
import PageTitle from '@/components/PageTitle.vue';
import PageImage from '@/components/PageImage.vue';

export default {
  components: { PageText, PageTitle, PageImage },
  data: () => ({
    publicPath: process.env.BASE_URL,
    p1: `
      To be human is to struggle. While seeking help can be challenging, you are now one step closer
      on your path to wellness and personal growth. I’m here to assist you on your journey to
      meaningful change--whether that entails emotional support during a challenging time in life,
      navigating mental illness, or you’re simply ready to take your life in a new direction. I
      recognize that no two individuals are the same and will customize our sessions to address your
      unique struggle. I work with adults (19+) and look forward to working with you to achieve your
      desired goals.
    `,
    p2: `
        Helping individuals cultivate mental wellness and emotional healing is my life’s greatest
        honor. I place special significance on the therapeutic relationship while offering clinical
        feedback to support my clients in reaching their aspirations. I work without an agenda and
        believe my clients are the experts on their own life. I am inclusive in my practice and
        LGBTQ+ affirming.
    `,
    p3: `
        On a more personal note, I was born in northern Idaho and have spent the majority of my life
        in this area. I love the beauty of nature in the Pacific Northwest, and find the outdoors to
        be an integral part of my own wellness. As more of an introvert, I enjoy meaningful
        conversations in intimate settings, reading and learning, creating art, hiking and spending
        time with my family and our pets.
    `,
  }),

};
</script>
