<template>
  <v-row>
    <v-col cols="12">
      <h1  style="font-size: 55px" class="d-none d-md-block text-center">{{text}}</h1>
      <h1  class="d-md-none text-center">{{text}}</h1>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ['text'],
};
</script>
